import REMIDI_CONSTANTS from "../constants/remidi";
import axios from "axios";
import { sync_profile_api, remidi_server_api } from "../apiConfig";
import { getToken } from "../../localstorage";

export const syncRemidiProfileViaServer = async (uid, userInfo) => {
  const data = {
    uid,
    action: 'sync-remidi-profile',
    userData: userInfo
  }
  return axios
    .post(remidi_server_api, data, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const syncRemidiProfile = async (uid, userInfo) => {
  try {
    const tokenRes = await loginUID(uid)
    if(!tokenRes?.status || !tokenRes?.data?.access_token){
      throw new Error(tokenRes?.reason ?? 'Invalid UID');
    }
    const remidiToken = tokenRes.data.access_token
    // getFullProfile
    const profileRes = await getFullProfile(remidiToken)
    if(!profileRes?.data?.data?.[0]){
      if(userInfo.nric){
        // if profile not found, then create new user API
        const userData = {
          fullname : userInfo.firstname + ' ' + userInfo.lastname,
          id_no : userInfo.nric,
          id_type : userInfo.id_type,
          passport_country : userInfo.passport_country,
          nationality : userInfo.nationality,
          mobile_phone: userInfo.mobile_phone,
          mobile_phone_country: userInfo.mobile_phone_country,
          gender : userInfo.gender,
          dob : userInfo.dob,
          race : null,
          referrer_code : null
        }
        const newUserRes = await createNewUser(remidiToken, userData)
      }
    }
    else{
      const remidiProfile = profileRes.data.data[0]
      // profile found, then need sync to our db
      const postData = {
        mobile: remidiProfile.telephone.mobile,
        email: remidiProfile.general.email,
        profile_image: '', // cannot
        first_name: remidiProfile.general.first_name,
        last_name: remidiProfile.general.last_name ?? '',
        gender: remidiProfile.general.gender == 'M' ? '1' : '2',
        id_no: remidiProfile.general.id_no,
        birthdate: remidiProfile.general.birthdate,
        citizenship: remidiProfile.general.citizenship,
        country_code: '',
        uid
      }
      const updateToDBRes = await syncProfileToDB(postData)
    }
    return {
      status: true,
      message: 'Sync Remidi Profile',
      data: profileRes?.data?.data?.[0] ?? {}
    }
  } catch (error) {
    console.error({error})
    return {
      status: false,
      reason: error
    }
  }
}

export const updateRemidiProfileViaServer = async (uid, userInfo) => {
  if(!userInfo?.id_no){
    // if no ic then no need update
    return true;
  }
  const data = {
    uid,
    action: 'update-remidi-profile',
    userData: userInfo
  }
  return axios
    .post(remidi_server_api, data, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const updateRemidiProfile = async (uid, userInfo) => {
  try {
    if(!userInfo?.id_no){
      // if no ic then no need update
      throw new Error("IC Required");
    }
    const tokenRes = await loginUID(uid)
    if(!tokenRes?.status || !tokenRes?.data?.access_token){
      throw new Error(tokenRes?.reason ?? 'Invalid UID');
    }
    const remidiToken = tokenRes.data.access_token
    // update user info
    const profileRes = await updateUserInfo(remidiToken, userInfo)
    if(userInfo?.contact){
      const telephoneData = {
        "home_phone" : userInfo.contact,
        "home_phone_country" : userInfo.contact_code,
      }
      // update user telepone
      const telephoneRes = await updateUserTelephone(remidiToken, telephoneData)
    }
    return {
      status: true,
      message: 'Sync Remidi Profile',
      data: profileRes ?? {}
    }
  } catch (error) {
    console.error({error})
    return {
      status: false,
      reason: error
    }
  }
}

// login firebase UID
export const loginUID = async (uid) => {
  try {
    let data = {}
    data.uid = uid
    const url = REMIDI_CONSTANTS.URL + '/api/v2/login-uid'
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /login-uid called",
        data: response.data
      }
    }
  } catch (error) {
    return {
      status: false,
      reason: error,
    }
  }
}

// get full profile
export const getFullProfile = async (token) => {
  try {
    let data = {}
    const url = REMIDI_CONSTANTS.URL + '/api/v2/profile'
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: data
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /profile called",
        data: response.data
      }
    }
  } catch (error) {
    console.log({error})
    return {
      status: false,
      reason: error,
    }
  }
}

// new user info(register)
export const createNewUser = async (token, data) => {
  try {
    const url = REMIDI_CONSTANTS.URL + '/api/v2/profile/new-user-info'
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /profile/new-user-info called",
        data: response.data
      }
    }
  } catch (error) {
    console.log({error})
    return {
      status: false,
      reason: error,
    }
  }
}

// update user info
export const updateUserInfo = async (token, data) => {
  try {
    const url = REMIDI_CONSTANTS.URL + '/api/v2/profile/user-info'
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /profile/user-info called",
        data: response.data
      }
    }
  } catch (error) {
    console.log({error})
    return {
      status: false,
      reason: error,
    }
  }
}

// update user address
export const updateUserAddress = async (token, data) => {
  try {
    const url = REMIDI_CONSTANTS.URL + '/api/v2/profile/user-address'
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /profile/user-address called",
        data: response.data
      }
    }
  } catch (error) {
    console.log({error})
    return {
      status: false,
      reason: error,
    }
  }
}

// update user telephone
export const updateUserTelephone = async (token, data) => {
  try {
    const url = REMIDI_CONSTANTS.URL + '/api/v2/profile/user-telephone'
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /profile/user-telephone called",
        data: response.data
      }
    }
  } catch (error) {
    console.log({error})
    return {
      status: false,
      reason: error,
    }
  }
}

// update user emergency
export const updateUserEmergency = async (token, data) => {
  try {
    const url = REMIDI_CONSTANTS.URL + '/api/v2/profile/user-emergency'
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if(response && response.status && response.data){
      return {
        status: true,
        message: "Remidi API /profile/user-emergency called",
        data: response.data
      }
    }
  } catch (error) {
    console.log({error})
    return {
      status: false,
      reason: error,
    }
  }
}

// sync profile to our db
export const syncProfileToDB = async (data) => {
  return axios
    .post(sync_profile_api, data, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};