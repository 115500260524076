import React, { useState } from 'react';
import VisionImage from '../../../assets/whoweare/vision.png';
import {Link} from 'react-router-dom';
import MissionImage from '../../../assets/whoweare/mission.png';

function WhoWeAreVisionSection () {
    const [isVision, setIsVision] = useState(true);

    const handleArrowClick = () => {
        setIsVision(!isVision);
    };

    const image = isVision ? VisionImage : MissionImage;
    const title = isVision ? 'Vision' : 'Mission';
    const subTitle = isVision ? 'The Preferred Healthcare Provider' : 'Deliver Quality Healthcare Services';
    const desc = isVision
        ? 'Our fundamental purpose is the delivery of exceptional health treatment, care and diagnosis to all our patients. We are dedicated to being the preferred provider of care, with innovative use of technology, experienced doctors and well-trained staff who collaborate to offer the best diagnosis and treatment plans.'
        : 'Our mission is to deliver quality healthcare services to all patients. We are committed to continuous improvement and excellence in clinical care.';
    const boxTitle = isVision ? 'Mission' : 'Vision';
    const boxDesc = isVision ? 'Deliver Quality Healthcare Services' : 'The Preferred Healthcare Provider';
    const missionBoxColor = isVision ? "#967F65" : "#371450";
    const arrowBoxColor = isVision ? "#371450" : "#967F65";

    return (
        <>
            <div className="who-we-are-vision-section">
              <div className="content-container">
                <h2>{title}</h2>
                <h3>{subTitle}</h3>
                <p>{desc}</p>
                <div className="mission-box" style={{backgroundColor: missionBoxColor}}>
                    <div style={{ height: "100%", display: "flex", alignItems: "start", justifyContent: "center", flexDirection: "column" }}>
                        <h3 className='box-title'>{boxTitle}</h3>
                        <h5 className='box-desc'>{boxDesc}</h5>
                    </div>
                </div>
              </div>
              <div className="image-container">
                <img
                    src={image}
                    alt=""
                    style={{ width: "100%" }}
                />
                <div className="arrow-box" onClick={handleArrowClick} style={{backgroundColor: arrowBoxColor}}>
                    <div className="arrow-icon">{'>'}</div>
                </div>
               </div>
            </div>

            {/* mobile view */}
            <div className="who-we-are-vision-section-mob">
                <div className="content-container">
                    <h2>{title}</h2>
                    <h3>{subTitle}</h3>
                    <p>{desc}</p>
                </div>
                <div className="image-container">
                <img
                    src={image}
                    alt=""
                    style={{ width: "100%" }}
                />
                </div>

                <div className="content-container" style={{marginTop:"40px"}}>
                    <h2>Mission</h2>
                    <h3>Deliver Quality Healthcare Services</h3>
                    <p>Our mission is to deliver quality healthcare services to all patients. We are committed to continuous improvement and excellence in clinical care.</p>
                </div>
                <div className="image-container">
                <img
                    src={MissionImage}
                    alt=""
                    style={{ width: "100%" }}
                />
                </div>
            </div>
        </>
       ) 
}

export default WhoWeAreVisionSection;