import React from 'react';
import WhoWeAreHero from './WhoWeAreHero';
import WhoWeAreVisionSection from './WhoWeAreVisionSection';
import WhoWeAreHealthCareSection from './WhoWeAreHealthCareSection';
import WhoAreWeInfoSection from './WhoWeAreIntroSection';

function WhoWeAre() {
    return (
        <>
        <div>
            <WhoWeAreHero/>
            <WhoWeAreVisionSection/>
            <WhoWeAreHealthCareSection/>
            <WhoAreWeInfoSection/>
        </div>
        </>
    )
};

export default WhoWeAre;