// NearestLocationInitializer.js

import React, { useContext, useEffect } from "react";
import { HospitalWithLocations } from "../constants/HospitalWithLocation";
import { CombinedFilterContext } from "../context/AppContext";

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371.0; // Earth radius in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};

const toRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

const getUserLocation = (callback) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLatitude = position.coords.latitude;
        const userLongitude = position.coords.longitude;
        callback(userLatitude, userLongitude);
      },
      (error) => {
        console.error("Error getting user location:", error.message);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};

const NearestLocationInitializer = () => {
  const {
    setDoctorFilters,
    setHealthPackageFilters,
    setCamproFilters,
    setEventsFilters,
    setHospitalLocation,
  } = useContext(CombinedFilterContext);

  useEffect(() => {
    getUserLocation((userLatitude, userLongitude) => {
      let nearestLocation = null;
      let nearestDistance = Number.POSITIVE_INFINITY;

      HospitalWithLocations?.forEach((location) => {
        const distance = calculateDistance(
          userLatitude,
          userLongitude,
          location.location.split(",")[0],
          location.location.split(",")[1]
        );
        if (distance < nearestDistance) {
          nearestLocation = location;
          nearestDistance = distance;
        }
      });

      if (nearestLocation && !(new URLSearchParams(window.location.search).get("location"))) {
        setHospitalLocation(nearestLocation.state);

        setDoctorFilters((prevFilters) => ({
          ...prevFilters,
          location: nearestLocation.hospital_name,
        }));

        setHealthPackageFilters((prevFilters) => ({
          ...prevFilters,
          location: nearestLocation.hospital_name,
        }));

        setCamproFilters((prevFilters) => ({
          ...prevFilters,
          location: nearestLocation.hospital_name,
        }));

        setEventsFilters((prevFilters) => ({
          ...prevFilters,
          location: nearestLocation.hospital_name,
        }));
      }
    });
  }, []);

  return null;
};

export default NearestLocationInitializer;
