import { useEffect, useState } from "react";
import { api_get_third_party_scripts } from "../api/hospital";

export default function useThirdPartyScript() {
  const [thirdPartyScript, setThirdPartyScript] = useState({});

  async function fetchThirdPartyScript(hospital_id) {
    try {
      const res = await api_get_third_party_scripts(hospital_id);
      if (res.status) {
        setThirdPartyScript(res.data);
      }
    } catch (error) {
      console.error("Error fetching third party scripts:", error);
    }
  }

  useEffect(() => {
    const headScripts = [];
    const bodyScripts = [];

    if (Object.entries(thirdPartyScript).length) {
      const tempHead = document.createElement("div");
      tempHead.innerHTML = thirdPartyScript.script;
      while (tempHead.firstChild) {
        try {
          tempHead.firstChild.setAttribute("name", "tps");
        } catch (e) {}
        headScripts.push(tempHead.firstChild);
        tempHead.firstChild.remove();
      }
      headScripts.forEach((headScript) => {
        document.head.appendChild(headScript);
      });

      const tempBody = document.createElement("body");
      tempBody.innerHTML = thirdPartyScript.body_script;
      while (tempBody.firstChild) {
        try {
          tempBody.firstChild.setAttribute("name", "tps");
        } catch (e) {}
        bodyScripts.push(tempBody.firstChild);
        tempBody.firstChild.remove();
      }
      bodyScripts.reverse().forEach((bodyScript) => {
        document
          .getElementById("root")
          .insertBefore(bodyScript, document.getElementById("root").firstChild);
      });
    }
    return () => {
      headScripts.forEach((script) => script?.remove());
      bodyScripts.forEach((script) => script?.remove());
    };
  }, [thirdPartyScript]);

  return { fetchThirdPartyScript };
}
