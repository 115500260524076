import Swal from "sweetalert2";
import axios from "axios";
import {
  post_login,
  api_token,
  post_register,
  post_otp,
  reset_password,
  forgot_password_phone,
} from "../apiConfig";
import { getToken, getPatientInfo } from "../../localstorage";

const get_token = async (action, token) => {
  const requestData = {
    action: action,
    token: token,
    patientId: getPatientInfo()?.id ?? null,
  };
  return axios
    .post(api_token, requestData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const handle_login = (
  email,
  password,
  setIsLoggedIn,
  setIsGuest,
  setPatientId,
  setLoggedInToken
) => {
  const postData = {
    email: email,
    password: password,
  };

  axios
    .post(post_login, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setIsLoggedIn(true);
        setIsGuest(false);
        setLoggedInToken(data.token);
        setPatientId(data.account.user_id);

        const { account } = data;
        localStorage.setItem("token", data.token);
        localStorage.setItem("firstname", account.firstname);
        localStorage.setItem("patient_id", account.user_id);
        localStorage.setItem("patient_nric", account.nric);

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        Swal.fire({
          icon: "error",
          title: "Login",
          text: "Invalid email or password. Please try again.",
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Login",
        text: error.message || "An error occurred during login.",
      });
    });
};

const api_register = async (postData) => {
  return axios
    .post(post_register, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const api_otp = async (otp_code, phone) => {
  const postData = {
    otp_code: otp_code,
    phone_no: phone,
  };

  return axios
    .post(post_otp, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const resetPassword = async (postData) => {
  return axios
    .post(reset_password, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const forgotPasswordPhone = async (postData) => {
  return axios
    .post(forgot_password_phone, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export {
  handle_login,
  get_token,
  api_register,
  api_otp,
  resetPassword,
  forgotPasswordPhone,
};
