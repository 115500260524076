import React from "react";
import image from "../../../assets/whoweare/private-healthcare-service.png"
import { Link } from "react-router-dom";

function WhoWeAreHealthCareSection() {
    return (
        <>
        <div className="who-we-are-healthcare-section">
                <img
                    src={image}
                    alt=""
                    style={{ width: "35%" }}
                />
                <div className="content-container">
                    <h2>
                        KPJ Healthcare Berhad ('KPJ or 'the Group') is Malaysia's leading provider of private healthcare services.
                    </h2>
                    <p>Since its introduction of the first private specialist hospital in Johor in 1981, the Group has been at the forefront of the healthcare industry. <br/><br/> Driven by its core values of Safety, Courtesy, Integrity, Professionalism and Continuous Improvement, KPJ's integrated network consists of more than 28 specialist hospitals located throughout the nation. With its expansion abroad, the Group currently has 2 hospitals in Indonesia, a sizable share in a hospital in Bangkok and a hospital in Bangladesh. <br/><br/>In addition, KPJ has investments in a retirement and age-care resort in Australia and in Sibu, Sarawak, Kuantan, as well as its own Senior Living Care (SLC) centre in Tawakkal Health Centre (THC) in Kuala Lumpur.<br/><br/> KPJ's competitive advantage lies in its extensive reach and presence in the highly competitive private healthcare industry. With its hospitals located in various parts of the nation, the Group's hospitals are easily accessible and offer a diverse range of medical specialist services, many of which are major firsts in the nation's healthcare industry.</p>
                </div>
        </div>

        {/* mobile view */}
        <div className="who-we-are-healthcare-section-mob">
                <div className="content-container">
                    <h2>
                        KPJ Healthcare Berhad ('KPJ or 'the Group') is Malaysia's leading provider of private healthcare services.
                    </h2>
                    <p>Since its introduction of the first private specialist hospital in Johor in 1981, the Group has been at the forefront of the healthcare industry. <br/><br/> Driven by its core values of Safety, Courtesy, Integrity, Professionalism and Continuous Improvement, KPJ's integrated network consists of more than 28 specialist hospitals located throughout the nation. With its expansion abroad, the Group currently has 2 hospitals in Indonesia, a sizable share in a hospital in Bangkok and a hospital in Bangladesh. <br/><br/>In addition, KPJ has investments in a retirement and age-care resort in Australia and in Sibu, Sarawak, Kuantan, as well as its own Senior Living Care (SLC) centre in Tawakkal Health Centre (THC) in Kuala Lumpur.<br/><br/> KPJ's competitive advantage lies in its extensive reach and presence in the highly competitive private healthcare industry. With its hospitals located in various parts of the nation, the Group's hospitals are easily accessible and offer a diverse range of medical specialist services, many of which are major firsts in the nation's healthcare industry.</p>
                </div>

                <img
                    src={image}
                    alt=""
                    style={{ width: "100%" }}
                />
        </div>
        </>
    )
}

export default WhoWeAreHealthCareSection;